import React from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import '../../styles/altonaevangelical.scss';

const AltonaEvangelical = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
            class: 'bd-altona-evangelical-sample'
        }}>
          <title>Brolga Digital || Altona Baptist Church</title>
          <link rel="stylesheet" href="https://use.typekit.net/xnh3scp.css"></link>
      </Helmet>

      {/* NAVIGATION */}
        <div uk-navbar='' className='uk-navbar-container uk-padding uk-padding-remove-vertical uk-light'>
            <div className='uk-navbar-left'>
                <Link className="uk-navbar-item uk-logo" to="#">Altona Evangelical Church</Link>
            </div>
            <div className='uk-navbar-right'>
                <ul className='uk-navbar-nav'>
                    <li><Link to='#'>home</Link></li>
                    <li><Link to='#'>about</Link></li>
                    <li><Link to='#'>services</Link></li>
                    <li><Link to='#'>contact</Link></li>
                </ul>
            </div>
        </div>

        <div className='uk-container uk-container-medium uk-margin-large-top'>
          <p style={{margin: '0 0 -30px 0'}}>Welcome To</p>
          <h1 className='uk-margin-medium-bottom'>Altona Evangelical Church</h1>
          <StaticImage src='../../images/altonaevangelical/giiv6l916u.jpg' class='uk-width-1-1 uk-margin-large-bottom'/>


          {/* BODY */}
          <div className='uk-flex uk-flex-wrap uk-grid-large' uk-grid=''>
            <div className='uk-width-3-4@s uk-padding-right'>
              <p>Our church is a Bible-believing church.</p>
              <p>We believe that the Bible is God's inspired Word, without error, sufficient in all matters of faith and conduct and relevant for today.</p>
              <p>Our church was built to be a place of worship where the Gospel is preached, the Word of God is studied in-depth,  the truths of Scripture are maintained and Christian people may fellowship and reach the community around them. We invite you to come and fellowship with us.</p>
              <p>Read our <Link to='#'>Statement of Faith.</Link></p>
            </div>

            <div className='uk-width-1-4@s'>
              <div className='uk-card uk-card-default uk-card-body announcement-card'>
                <h3>All Welcome!</h3>
                <p>Sunday 10am</p>
                <p>68-72 Nelson Ave,<br />
                  Altona Meadows</p>
              </div>
            </div>

            <div className='uk-width-1-4@s'>
              <StaticImage src='../../images/altonaevangelical/Cnr-Millers_Francis_Geelong.jpg' />
            </div>

            <div className='uk-width-3-4@s uk-padding-left'>
              <p>On the 7th November 1968, at 16 Myrtle Grove, Altona, twelve people met to discuss the possibility of forming a Church.</p>
              <p>Those twelve people formed what was known as the West Altona Gospel Mission. In those days it was just bare paddocks - the only sign of human habitation being the remains of an old house beside a big tree, commonly known as Trigs Farm. The name Meadows was unknown in those days. Of the twelve; three are still here with us, three have gone to be with the Lord, and six have left the district to serve the Lord in other places.</p>
              <p>Read our <Link to='#'>Church History</Link>.</p>
            </div>
          </div>

          {/* GALLERY */}
          <div className="uk-section uk-section-default uk-padding-remove-top uk-section-xsmall uk-margin-large-top">
            <div className="" uk-slider='autoplay: true'>
                <ul className="uk-slider-items uk-child-width-1-5 bd-image-fade">
                    <li>
                        <StaticImage src="../../images/altonaevangelical/18699955_1870821723168688_2662877268963223388_n.jpg" alt="" uk-cover='' aspectRatio={1/1}/>
                    </li>
                    <li>
                        <StaticImage src="../../images/altonaevangelical/16508209_1822855197965341_53704352233982590_n.jpg" alt="" uk-cover='' aspectRatio={1/1}/>
                    </li>
                    <li>
                        <StaticImage src="../../images/altonaevangelical/46823814_2163096190607905_7683859825855299584_n.jpg" alt="" uk-cover='' aspectRatio={1/1}/>
                    </li>
                    <li>
                        <StaticImage src="../../images/altonaevangelical/75481731_2389512584632930_7989765551503179776_n.jpg" alt="" uk-cover='' aspectRatio={1/1} transformOptions={{cropFocus: 'right'}}/>
                    </li>
                    <li>
                        <StaticImage src="../../images/altonaevangelical/interior2.png" alt="" uk-cover='' aspectRatio={1/1} transformOptions={{cropFocus: 'center'}}/>
                    </li>
                </ul>
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <footer className='uk-section uk-section-secondary uk-light uk-margin-large-top'>
          <div className='uk-container uk-container-medium uk-flex uk-flex-between uk-child-width-1-1 uk-child-width-1-2@s'>
            
            <div>
              <h3 style={{color: '#869E83'}}>Altona Evangelical Church</h3>

              <div className='uk-margin-small' uk-grid=''>
                <span uk-icon="icon: mail"></span>
                <p>PO Box 1006 Altona Meadows Vic 3028</p>
              </div>
              <div className='uk-margin-small' uk-grid=''>
                <span uk-icon="icon: bolt"></span>
                <p>info@altonachurch.org.au</p>
              </div>
              <div className='uk-margin-small' uk-grid=''>
                <span uk-icon="icon: receiver"></span>
                <p>(03) 9315 6140</p>
              </div>
              <div className='uk-margin-medium' uk-grid=''>
                <span uk-icon="icon: location"></span>
                <p>68-72 Nelson Ave, Altona Meadows</p>
              </div>
            </div>

            <div>
              <ul class="uk-nav uk-text-right@s uk-nav-right">
                <li><Link to='#'>home</Link></li>
                <li><Link to='#'>about</Link></li>
                <li><Link to='#'>statement of faith</Link></li>
                <li><Link to='#'>history</Link></li>
                <li><Link to='#'>missions</Link></li>
                <li><Link to='#'>services</Link></li>
                <li><Link to='#'>contact</Link></li>
              </ul>  
            </div>          
          </div>

        </footer>

        {/* COPYRIGHT */}
        <div className='bd-copyright uk-text-center uk-padding-small'>
            <p className='uk-margin-remove-bottom'>&copy; <Link to='https://brolgadigital.com.au'>Brolga Digital</Link> {new Date().getFullYear()}</p>
        </div>
    </>
  )
}

export default AltonaEvangelical